/* eslint-disable import/prefer-default-export */
import { PostMetaFields } from 'src/pages/blog';
import { PostCardProps } from 'src/components/Blog/PostCard/PostCard';

export const postCardAdapter = ({
  bannerImage = null,
  components = [],
  excerpt = '',
  metaTitle = '',
  pageSlug,
  published,
  tags = [],
}: PostMetaFields): PostCardProps => {
  return {
    date: published,
    components,
    excerpt,
    image: {
      src: bannerImage?.fields?.file?.url,
      alt: bannerImage?.fields?.description || '',
    },
    link: `/blog/${pageSlug}/`,
    tags: tags.map((tag) => {
      return tag.fields?.tag;
    }),
    title: metaTitle,
  };
};
